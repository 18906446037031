import React from "react";

import { Avatar, Badge, Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { AVATAR_SIZE } from "../../helpers/constant";
import playerCSS from "./PlayerEndScreen.module.css";
// import { faCrown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import crown from "../Player/trivia-crown-icon-2.png";

export default function PlayerEndSreen({ index, player }) {
    const theme = useTheme();
    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                columnSpacing={2}
                sx={{ py: 0, m: 0, borderTop: "1px solid gray" }}
            >
                <Grid
                    item
                    align="center"
                    xs={1.25}
                    sm={1.25}
                    sx={{
                        bgcolor: "#222222",
                        py: 2,
                        pl: "0 !important",
                    }}
                >
                    <Typography noWrap variant="body1">
                        {index + 1}
                    </Typography>
                </Grid>
                <Grid item xs={7.75} sm={7.75}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{
                                width: "auto",
                                mt: player.isLeader ? 2 : 0,
                            }}
                        >
                            <Avatar
                                src={player.avatar}
                                sx={{
                                    width: {
                                        xs: AVATAR_SIZE.other.xs,
                                        sm: AVATAR_SIZE.other.sm,
                                    },
                                    height: {
                                        xs: AVATAR_SIZE.other.xs,
                                        sm: AVATAR_SIZE.other.sm,
                                    },
                                }}
                            >
                                {" "}
                            </Avatar>

                            <img
                                src={crown}
                                className={
                                    player.isLeader
                                        ? playerCSS.crown
                                        : playerCSS.noCrown
                                }
                                alt="bg1"
                            />
                        </Badge>
                        <Typography
                            noWrap
                            variant="body1"
                            sx={{
                                ml: 2,
                                maxWidth: { xs: "120px", md: "150px" },
                            }}
                        >
                            {player.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <Grid
                        item
                        align="center"
                        sx={{
                            fontWeight: "bold",
                            fontSize: "1.6rem",
                        }}
                    >
                        {player.score}
                    </Grid>
                    <Grid item align="center">
                        <Typography
                            color={theme.palette.textColor.faded}
                            sx={{
                                fontSize: "1.1rem",
                            }}
                        >
                            POINT{player.score === 1 ? "" : "S"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
