/* eslint-disable default-case */
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import GeneralButton from "../Button/Button";
import { useTheme } from "@mui/material/styles";
import LinkIcon from "@mui/icons-material/Link";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import { useSelector, useDispatch } from "react-redux";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";
import { useNavigate } from "react-router-dom";
import { IS_HOST } from "../../helpers/Global";
import { openCountDownModal } from "../../reducers/MessageReducer";
import CountDownModal from "../CountDownModal/CountDownModal";

const InviteSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const players = useSelector(({ game }) => game.gamePlayers);

    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const roomId = useSelector(({ game }) => game.roomId);
    const gameHost = useSelector(({ game }) => game.gameHost);
    const me = useSelector(({ player }) => player);
    const startingTimer = useSelector(({ timer }) => timer.startingTimer);
    const isHost = IS_HOST(gameHost, me.playerId);

    const startButtonText =
        Object.keys(players).length === 1 ? "Start Solo" : "Start Game";

    const handleStartClick = () => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.START_GAME, {
            roomId: roomId,
            playerId: me.playerId,
        });
    };

    useEffect(() => {
        if (parseInt(startingTimer) === 3) {
            // on startingTimer start trigger the countdown modal
            dispatch(openCountDownModal(true));
        }

        if (parseInt(startingTimer) === 0) {
            let message = { action: "startGame" };
            // method to send message to parent outside iframe
            window.parent.postMessage(message, "*");
            navigate("/play");
        }
    }, [dispatch, navigate, startingTimer]);

    const handleInviteClick = (e) => {
        let message = { action: "inviteFriends" };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: { xs: "5vh", md: "100%" },
                }}
            >
                <GeneralButton
                    bgcolor={theme.palette.secondaryButton.main}
                    color={theme.palette.secondaryButton.textColor}
                    mRight={isHost ? "10px" : "0px"}
                    handleButtonClick={(e) => handleInviteClick(e)}
                    value="Add Friends"
                    hoverColor={theme.palette.hoverColor.main}
                    minHeight={"48px"}
                    maxHeight={"75px"}
                >
                    <LinkIcon sx={{ mr: "5px", transform: "rotate(130deg)" }} />
                    Add Friends
                </GeneralButton>
                {isHost && (
                    <GeneralButton
                        bgcolor="#00B3E2"
                        color="#000"
                        handleButtonClick={(e) => handleStartClick(e)}
                        filter={"brightness(1.5)"}
                        id="playButton"
                        minHeight={"48px"}
                        maxHeight={"75px"}
                        border="2px solid #16F5B4"
                    >
                        <VideogameAssetIcon
                            sx={{
                                mr: "10px",
                                color: "#16F5B4",
                                fontSize: `1.6rem`,
                            }}
                        />
                        {startButtonText}
                    </GeneralButton>
                )}
            </Box>
            <CountDownModal />
        </>
    );
};

export default InviteSection;
