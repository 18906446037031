const initialState = {
  playerId: null,
  playerName: null,
  playerAvatar: null
};

const PlayerReducer = (state = initialState, action) =>
{
  switch (action.type)
  {
    case "SAVE_YOUR_PROFILE":
      return Object.assign({}, state, {
        ...state,
        playerId: action.data.playerId,
        playerName: action.data.playerName,
        playerAvatar: action.data.playerAvatar
      });

    default:
      return state;
  }
};

//adding the player details
export const saveYourProfile = (playerId, playerName, playerAvatar) =>
{
  return {
    type: "SAVE_YOUR_PROFILE",
    data: { playerId, playerName, playerAvatar },
  };
};




export default PlayerReducer;
