import React, { useRef, useEffect, useState } from "react";
import { CssBaseline, Box, Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import PlayersEndScreen from "../../components/PlayersEndScreen/PlayersEndScreen";
import { useSelector } from "react-redux";
import EndScreenButtons from "../../components/EndScreenButtons/EndScreenButtons";
import Confetti from "react-confetti";

const EndScreen = () => {
    const endScreenContainer = useRef();
    const theme = useTheme();
    const [endScreenHeight, setEndScreenHeight] = useState(0);

    const token = useSelector(({ token }) => token.token);
    const title = "Watch Party";
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const me = useSelector(({ player }) => {
        return player.playerId;
    });

    const roomId = useSelector(({ game }) => {
        return game.roomId;
    });

    const winner = players.find((player) => player.id === me);

    useEffect(() => {
        setEndScreenHeight(endScreenContainer.current.clientHeight);
    }, [endScreenHeight]);

    return (
        <>
            <CssBaseline />
            <Container
                maxWidth="lg"
                sx={{
                    padding: "0",
                    height: "auto",
                }}
                ref={endScreenContainer}
            >
                {winner !== undefined && winner.isLeader === true ? (
                    <Confetti
                        width={window.innerWidth - 16}
                        height={endScreenHeight}
                    />
                ) : (
                    ""
                )}
                <Grid
                    container
                    sx={{
                        ml: 0,
                        pt: { xs: "0", md: 4 },
                    }}
                    spacing={{ xs: 0, md: 2 }}
                >
                    <Grid item xs={12} lg={6}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                sx={{
                                    height: { xs: "280px", md: "360px" },
                                    width: { xs: "100%" },
                                }}
                            >
                                <iframe
                                    src={`${
                                        process.env.REACT_APP_WM_URL
                                    }/triviaEndScreen?title=${title}&roomId=${roomId}&token=${token}&header=${"Watch Party"}`}
                                    width="100%"
                                    height="100%"
                                    title="ScratchSection"
                                    style={{
                                        borderStyle: "none",
                                        borderRadius: "8px",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PlayersEndScreen />
                        <EndScreenButtons />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EndScreen;
