import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, List, Stack } from "@mui/material";
import Player from "../Player/Player";
import useSocketEvents from "../../hooks/useSocketEvents";
import { playerAnswered } from "../../reducers/GameReducer";
import {
    GAME_STATUS,
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
} from "../../helpers/constant";
import { useTheme } from "@mui/material/styles";
import styles from "./Players.module.css";
import { updatePlayerAnswer } from "../../reducers/QuestionsReducer";

const Players = ({ location = "top" }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const playersContainer = useRef();

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const gameStatus = useSelector(({ game }) => {
        return game.gameStatus;
    });

    const handlePlayerAnswered = useCallback(
        (playerId, answer) => {
            dispatch(playerAnswered(playerId));
            //also store what the player answered
            dispatch(updatePlayerAnswer(playerId, answer));
        },
        [dispatch]
    );

    //add PLAYER_ANSWERED event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_ANSWERED,
        null,
        handlePlayerAnswered
    );

    return (
        <Box
            ref={playersContainer}
            className={styles.playersContainer}
            sx={theme.playSection[location].container.style}
        >
            <List
                sx={theme.playSection[location].list.style}
                component={Stack}
                direction={theme.playSection[location].list.direction}
                alignItems={{ xs: "flex-end", lg: "center" }}
            >
                {players !== undefined &&
                    players.map((player) => {
                        return (
                            <Player
                                key={player.id}
                                player={player}
                                location={location}
                            />
                        );
                    })}
                {gameStatus === GAME_STATUS.WAITING && <OpenSpot />}
            </List>
        </Box>
    );
};

export default Players;

const OpenSpot = () => {
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    let freeSpots = [
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
    ];

    return (
        <>
            {freeSpots.map((spot, index) => {
                return (
                    index + players.length < 6 && (
                        <Player key={index} player={spot} />
                    )
                );
            })}
        </>
    );
};
