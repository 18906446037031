import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function AlertDialog({
    open = false,
    title = "",
    message = "",
    btnPos = "Yes",
    btnNeg = "No",
    posCallback,
    negCallback,
}) {
    function handlePosClick() {
        if (posCallback !== null) {
            posCallback();
        }
    }

    function handleNegClick() {
        if (negCallback !== null) {
            negCallback();
        }
    }

    const theme = useTheme();

    return (
        <>
            <Dialog fullscreen={"true"} open={open}>
                <Box
                    sx={{
                        backgroundColor: `${theme.palette.backgroundColor.main}`,
                        color: "#fff",
                    }}
                >
                    <DialogTitle
                        sx={{
                            backgroundColor: `${theme.palette.backgroundColor.main}`,
                            color: "#fff",
                            fontSize: { xs: "1.25rem", sm: "1.5rem" },
                        }}
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            backgroundColor: `${theme.palette.backgroundColor.main}`,
                        }}
                    >
                        <DialogContentText
                            sx={{
                                color: "#fff",
                                fontSize: { xs: "1.1rem", sm: "1.3rem" },
                            }}
                        >
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            backgroundColor: `${theme.palette.backgroundColor.main}`,
                        }}
                    >
                        <Button
                            onClick={handleNegClick}
                            sx={{ color: `${theme.palette.wmColor.main}` }}
                        >
                            {btnNeg}
                        </Button>
                        <Button
                            onClick={handlePosClick}
                            autoFocus
                            sx={{ color: `${theme.palette.wmColor.main}` }}
                        >
                            {btnPos}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
