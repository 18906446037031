import React, { useEffect } from "react";

import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import PlayerEndSreen from "../PlayerEndScreen/PlayerEndSreen";

export default function PlayersEndScreen() {
    const theme = useTheme();
    const players = useSelector(({ game }) => game.gamePlayers);

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ my: 1 }}
            >
                <Box
                    sx={{
                        p: 1,
                        width: { xs: "100%" },
                    }}
                >
                    <Typography
                        component="h5"
                        variant="h5"
                        align="center"
                        sx={{
                            display: { xs: "block", md: "block" },
                            pb: "6px",
                            color: theme.palette.wmColor.main,
                        }}
                    >
                        Leaderboard
                    </Typography>
                    <Grid container>
                        {players.map((player, index) => {
                            return (
                                <PlayerEndSreen
                                    key={index}
                                    index={index}
                                    player={player}
                                />
                            );
                        })}
                    </Grid>
                </Box>
            </Box>
        </>
    );
}
