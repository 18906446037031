import { Avatar, Box, Typography, CssBaseline, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";

import styles from "./MultipleAnswers.module.css";

const MultipleAnswers = ({
    questionObj,
    buttonBackgroundColor,
    correctAnswer,
    matches,
    clicked,
    selectedAns,
    handleAnswerClick,
    roundStart,
}) => {
    const theme = useTheme();

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    //method to get list of players that answered to this answer
    function buildPlayers(playerAnswerArray) {
        const max = 4;
        const avatars = [];

        playerAnswerArray.every((playerId, index) => {
            if (index >= max) {
                avatars.push(
                    <Avatar
                        className={styles.avatarItem}
                        sx={{
                            fontWeight: "700 !important",
                            fontSize: "12px",
                            color: "#343434 !important",
                            backgroundColor: "#FFFFFF",
                        }}
                    >
                        +{playerAnswerArray.length}
                    </Avatar>
                );
                return false;
            }

            const playerInfo = players.find((p) => p.id == playerId);
            avatars.push(
                <Avatar
                    key={index}
                    className={styles.avatarItem}
                    alt={playerInfo.name}
                    src={playerInfo.avatar}
                    sx={{
                        backgroundColor: playerInfo.bgColor,
                        padding: 0,
                        height: { xs: "35px", md: "45px" },
                        width: { xs: "35px", md: "45px" },
                        lineHeight: { xs: "35px", md: "45px" },
                    }}
                />
            );
            return true;
        });

        return avatars;
    }

    return (
        <Box
            id="answerContainer"
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                position: "relative",
                gap: "12px",
                pl: {
                    xs: theme.pLeft.secondary,
                    md: 0,
                },
                pr: {
                    xs: theme.pRight.secondary,
                    md: 0,
                },
            }}
        >
            {questionObj &&
                questionObj.answers.map((answer, index) => (
                    <Button
                        name={answer.id}
                        key={answer.id}
                        sx={{
                            position: "relative",
                            backgroundColor:
                                roundStart === false &&
                                parseInt(correctAnswer) === parseInt(answer.id)
                                    ? theme.palette.correct.main
                                    : buttonBackgroundColor,
                            color: `${theme.palette.textColor.main} !important`,
                            height: matches ? "50px" : "40px",
                            borderRadius: theme.borderRadius.secondary,
                            "&:hover": {
                                backgroundColor:
                                    roundStart === false &&
                                    parseInt(correctAnswer) ===
                                        parseInt(answer.id)
                                        ? theme.palette.correct.main
                                        : buttonBackgroundColor,
                                color: `${theme.palette.textColor.main} !important`,
                            },
                            opacity:
                                clicked &&
                                selectedAns !== answer.id &&
                                parseInt(correctAnswer) !== answer.id
                                    ? "0.5"
                                    : "1",
                            filter:
                                clicked && selectedAns === answer.id
                                    ? "brightness(1.2)"
                                    : "brightness(1)",
                        }}
                        onClick={() => handleAnswerClick(answer.id)}
                        disabled={clicked || roundStart === false}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: "600",
                                textTransform: "capitalize",
                                lineHeight: "1",
                            }}
                        >
                            <span
                                style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                }}
                            >
                                {answer.answer}{" "}
                            </span>
                            {roundStart === false &&
                            parseInt(correctAnswer) === answer.id ? (
                                <DoneIcon
                                    sx={{
                                        fontWeight: "700",
                                        lineHeight: "normal",
                                        verticalAlign: "middle",
                                        paddingLeft: "5px",
                                        paddingBottom: "5px",
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Typography>
                        <Box
                            className={styles.avatars}
                            sx={{ position: "absolute", right: 5 }}
                        >
                            {buildPlayers(answer.players ?? [])}
                        </Box>
                    </Button>
                ))}
        </Box>
    );
};

export default MultipleAnswers;
