const initialState = {
    videos: [],
    media: null,
    duration: 0,
    currentTimer: 0,
};

const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_VIDEOS":
            return Object.assign({}, state, {
                videos: action.data,
            });

        case "UPDATE_VIDEO":
            return Object.assign({}, state, {
                media: action.data.media,
                duration: action.data.duration,
                currentTimer: action.data.timer,
            });

        default:
            return state;
    }
};

//get all answers
export const updateVideos = (videos) => {
    return async (dispatch) => {
        dispatch({
            type: "UPDATE_VIDEOS",
            data: videos,
        });
    };
};

//update video
export const updateVideo = (video) => {
    return async (dispatch) => {
        dispatch({
            type: "UPDATE_VIDEO",
            data: video,
        });
    };
};

export default VideoReducer;
