import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import PlayerLobbyView from "../PlayerLobbyView/PlayerLobbyView";
import { addMode } from "../../reducers/SettingsReducer";
import { GAME_MODE, GAME_TYPE } from "../../helpers/constant";

const Settings = ({ contentContainerHeight }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    let gameType = useSelector(({ game }) => game.gameType);
    let currentMode = useSelector(({ settings }) => settings.currentMode);
    let currentTitle = useSelector(({ settings }) => settings.currentTitle);

    useEffect(() => {
        // only set default if currentMode is null
        if (currentMode == null) {
            let defaultMode =
                gameType === GAME_TYPE.CATEGORY
                    ? GAME_MODE.TRAINING
                    : GAME_MODE.SPRINT;

            // update default mode on first load
            dispatch(addMode(defaultMode));
        }
    }, [dispatch, gameType, currentMode]);

    return (
        <Box
            sx={{
                height: { xs: "auto", md: `100%` },
            }}
        >
            <Box
                sx={{
                    flexDirection: "row",
                    width: "100%",
                    pl: { xs: theme.pLeft.main, md: theme.px16.main },
                    pr: { xs: theme.pRight.main, md: theme.px16.main },
                    pt: { xs: theme.px10.main, md: theme.px10.main },
                    pb: theme.px8.main,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: { xs: "center", md: "center" },
                        textTransform: "uppercase",
                        fontWeight: "medium",
                        fontSize: { xs: 12, md: 14 },
                        pb: theme.px8.main,
                        lineHeight: "1",
                        color: theme.palette.wmColor.main,
                    }}
                >
                    Guess the List
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: { xs: "center", md: "center" },
                        fontWeight: "bold",
                        fontSize: { xs: 16, md: 20 },
                        textTransform: "capitalize",
                        lineHeight: "1.1",
                    }}
                >
                    {currentTitle}
                </Typography>
            </Box>
            <PlayerLobbyView />
        </Box>
    );
};

export default Settings;
