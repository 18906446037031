/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import GeneralButton from "../Button/Button";
import playLong from "../InviteSection/playLong.png";
import { useSelector } from "react-redux";
import { titleCase } from "../../helpers/Global";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";
import AlertDialog from "../AlertDialog/AlertDialog";

export default function EndScreenButtons() {
    const theme = useTheme();
    const gameType = useSelector(({ game }) => game.gameType);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );
    const currentPid = useSelector(({ settings }) => settings.currentPid);

    const me = useSelector(({ player }) => player);
    const players = useSelector(({ game }) => game.gamePlayers);

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    // alert
    let [showDialog, setShowDialog] = useState(false);
    let [alertTitle, setAlertTitle] = useState("Confirm");
    let [alertMessage, setAlertMessage] = useState("");
    let [showTooltip, setShowTooltip] = useState(false);

    function handleShareResults() {
        let url = null;
        let title = null;
        const player = players.find((player) => {
            return player.id === me.playerId;
        });
        switch (gameType) {
            case "category":
                url = `${process.env.REACT_APP_URL}/${
                    process.env.REACT_APP_END_POINT
                }/${titleCase(currentCategory.replace(/ /g, "+"))}`;
                title = `I tested my trivia power in the ${
                    currentCategory && currentCategory.toLowerCase() === "all"
                        ? ""
                        : currentCategory
                } category and scored ${
                    player.score
                } point(s)! Test your trivia power too!`;
                break;

            case "video":
                url = `${process.env.REACT_APP_URL}/${process.env.REACT_APP_END_POINT}/${currentPid}`;
                title = `I tested my trivia power and scored ${player.score} point(s)! Test your trivia power too!`;
                break;
        }

        let message = { action: "shareResults", url: url, title: title };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    }

    function handleBackToLobby() {
        setShowDialog(true);
        const message = "Would you like to share your feedback ?";
        setAlertMessage(message);
    }

    // if selected yes in feedback dialog
    const alertDialogYes = () => {
        setShowDialog(false);
        let message = {
            action: "triggerFeedback",
            url: process.env.REACT_APP_FEEDBACK_URL,
        };
        window.top.postMessage(message, "*");

        socketFromStore.emit(SOCKET_SEND_EVENTS.PLAY_AGAIN, me.playerId);
    };

    // if selected no in feedback dialog
    const alertDialogNo = () => {
        setShowDialog(false);
        socketFromStore.emit(SOCKET_SEND_EVENTS.PLAY_AGAIN, me.playerId);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Box
                display="flex"
                sx={{
                    width: { xs: "100%" },
                    px: { xs: 2, md: 0 },
                    py: 0,
                    pb: theme.px16.main,
                }}
            >
                <GeneralButton
                    bgcolor={theme.palette.secondaryButton.main}
                    color={theme.palette.secondaryButton.textColor}
                    mRight={"10px"}
                    hoverColor={theme.palette.hoverColor.main}
                    handleButtonClick={handleShareResults}
                    id={"trivia_share_results"}
                    minHeight={"48px"}
                    maxHeight={"75px"}
                >
                    Share Results
                </GeneralButton>
                <GeneralButton
                    bgcolor={theme.palette.secondaryButton.main}
                    color={theme.palette.secondaryButton.textColor}
                    backgroundImage={playLong}
                    filter={"brightness(1.5)"}
                    handleButtonClick={handleBackToLobby}
                    id={"trivia_back_to_lobby"}
                    minHeight={"48px"}
                    maxHeight={"75px"}
                >
                    Back to Lobby
                </GeneralButton>
            </Box>
            <AlertDialog
                open={showDialog}
                title={alertTitle}
                message={alertMessage}
                posCallback={alertDialogYes}
                negCallback={alertDialogNo}
            />
        </Box>
    );
}
