const initialState = {
    id: null,
    index: 0,
    question: "",
    type: null,
    answers: [
        {
            answer: "",
            id: 0,
        },
        {
            answer: "",
            id: 1,
        },
        {
            answer: "",
            id: 2,
        },
        {
            answer: "",
            id: 3,
        },
    ],
    questionsCount: 0,
    maxSeconds: null,
};

const QuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_QUESTION":
            return Object.assign({}, state, {
                id: action.id,
                index: action.index,
                question: action.question,
                type: action.questionType,
                answers: action.answers,
            });

        case "UPDATE_QUESTION_INDEX":
            return Object.assign({}, state, {
                index: action.data,
            });

        case "UPDATE_QUESTION_COUNT":
            return Object.assign({}, state, {
                questionsCount: action.data,
            });

        case "UPDATE_MAX_SECONDS":
            return Object.assign({}, state, {
                maxSeconds: action.data,
            });

        case "UPDATE_PLAYER_ANSWER":
            //create a copy
            const newArray = [...state.answers];
            newArray[action.answer]["players"] =
                newArray[action.answer].players ?? [];
            newArray[action.answer]["players"].push(action.playerId);

            return Object.assign({}, state, {
                answers: newArray,
            });

        default:
            return state;
    }
};

//add questions
export const addQuestions = (index) => {
    return async (dispatch) => {
        dispatch({
            type: "ADD_QUESTIONS_OBJECT",
            data: index,
        });
    };
};

//update question
export const updateQuestion = (id, index, question, questionType, answers) => {
    return {
        type: "UPDATE_QUESTION",
        id: id,
        index: index,
        question: question,
        questionType: questionType,
        answers: answers,
    };
};

//update question count
export const updateQuestionCount = (count) => {
    return {
        type: "UPDATE_QUESTION_COUNT",
        data: count,
    };
};

//update maxSeconds
export const updateMaxSeconds = (seconds) => {
    return {
        type: "UPDATE_MAX_SECONDS",
        data: seconds,
    };
};

//update player answer
export const updatePlayerAnswer = (playerId, answer) => {
    return {
        type: "UPDATE_PLAYER_ANSWER",
        playerId: playerId,
        answer: answer,
    };
};

export default QuestionsReducer;
