/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";

import Players from "../../components/Players/Players";
import LobbySettings from "../../components/LobbySettings/LobbySettings";
import styles from "./Lobby.module.css";
import { useLocation } from "react-router-dom";
import HowTo from "../../components/HowTo/HowTo";
import { useTheme } from "@mui/material/styles";
import useSocket from "../../hooks/useSocket";
import { addToken } from "../../reducers/TokenReducer";
import { saveYourProfile } from "../../reducers/PlayerReducer";
import { updateRoomId } from "../../reducers/GameReducer";
import { useSelector, useDispatch } from "react-redux";
import useSocketEvents from "../../hooks/useSocketEvents";
import {
    GAME_STATUS,
    SOCKET_ACTIONS,
    SOCKET_SEND_EVENTS,
} from "../../helpers/constant";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useNavigate } from "react-router-dom";
import { isMobileOrTablet } from "../../helpers/Global";

const Lobby = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [containerHeight, setContainerHeight] = useState("72vh");
    const [questionsContainerHeight, setQuestionsContainerHeight] =
        useState("100%");

    const playersContainer = useRef();
    const howToContainer = useRef();
    const playersBox = useRef();

    const search = useLocation().search;
    let token = useSelector(({ token }) => token.token);
    let roomId = useSelector(({ game }) => game.roomId);

    const me = useSelector(({ player }) => player);
    const [oldPlayerId, setOldPlayerId] = useState(null);
    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const error = useSelector(({ message }) => message.error);
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    // get all the player details when lobby page is opened
    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const playerId = searchParams.get("playerId");
        const playerName = searchParams.get("playerName");
        const playerAvatar = searchParams.get("playerAvatar");

        //save your profile to state
        dispatch(saveYourProfile(playerId, playerName, playerAvatar));
        setOldPlayerId(searchParams.get("oldPlayerId"));

        const token = searchParams.get("token");
        const loggedIn = searchParams.get("loggedIn");
        if (token !== null) {
            dispatch(addToken(token, loggedIn));
        }

        dispatch(updateRoomId(searchParams.get("roomId")));
    }, [dispatch, search]);

    //establish socket connection
    useSocket(token);

    //connect to henrique backend
    const playerId = me.playerId,
        playerName = me.playerName,
        playerAvatar = me.playerAvatar;

    useSocketEvents(
        SOCKET_ACTIONS.SEND,
        SOCKET_SEND_EVENTS.JOIN_ROOM,
        [{ playerId, playerName, playerAvatar, roomId, oldPlayerId }],
        null,
        false
    );

    useEffect(() => {
        if (socketFromStore != null && roomId != null) {
            // setInterval(() => {
            //     console.log("JOIN ROOM... settimeout");
            //     socketFromStore.emit(SOCKET_SEND_EVENTS.JOIN_ROOM, {
            //         playerId,
            //         playerName,
            //         playerAvatar,
            //         roomId,
            //         oldPlayerId,
            //     });
            // }, 2000);
        }
    }, [socketFromStore, roomId]);

    useEffect(() => {
        if (gameStatus === GAME_STATUS.END) {
            navigate("/end");
        }

        if (gameStatus === GAME_STATUS.INGAME) {
            navigate("/play");
        }
    }, [dispatch, gameStatus, navigate]);

    return (
        /* if no error, lobby page, else errorPage */
        error === "" ? (
            <>
                <Container
                    maxWidth="md"
                    style={{ padding: "0", maxWidth: "750px" }}
                >
                    <Grid
                        container
                        sx={{
                            ml: 0,
                            height: "100%",
                            pt: { xs: "0", md: "2vh" },
                        }}
                        spacing={{ xs: "0" }}
                        className={styles.backgroundColor}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%" },
                                overflow: "hidden",
                                paddingTop: theme.px16.main,
                                paddingBottom: theme.px10.main,
                            }}
                            ref={playersBox}
                        >
                            <Players />
                        </Box>

                        <LobbySettings />

                        <Box
                            ref={howToContainer}
                            sx={{
                                pt: { sm: ".8rem", md: ".2rem" },
                                width: { xs: "100%" },
                                overflow: "hidden",
                            }}
                        >
                            <HowTo />
                        </Box>
                    </Grid>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                </Container>
            </>
        ) : (
            <ErrorPage />
        )
    );
};

export default Lobby;
