import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        wmColor: { main: "#00b3e2" },
        textColor: { main: "#fafafa", faded: "lightgrey" },
        backgroundColor: { main: "#181818" },
        gameModeActive: { main: "#2B235C" },
        secondaryButton: { main: "#3f3f3f" },
        voteButton: { main: "#E5E5E5" },
        hoverColor: { main: "#5c636a" },
        correct: { main: "green" },
        inCorrect: { main: "red" },
    },
    borderRadius: {
        primary: "8px",
        secondary: "4px",
    },
    pLeft: {
        main: "8px",
        secondary: "15px",
    },
    pRight: {
        main: "8px",
        secondary: "15px",
    },
    px8: {
        main: "8px",
    },
    px10: {
        main: "10px",
    },
    px16: {
        main: "16px",
    },
    categoryButtons: {
        px: { xs: "8px", md: "20%" },
    },
    howTo: {
        imageSize: { xs: "90px", md: "120px" },
        container: { mobile: 200 },
    },
    avatar: {
        me: { size: { xs: 56, sm: 50, lg: 55 } },
        other: { size: { xs: 46, sm: 44, lg: 55 } },
        leaderboard: { size: { xs: 35, sm: 45, lg: 45 } },
    },
    playSection: {
        top: {
            container: {
                style: {
                    position: "relative",
                    width: { xs: "100%" },
                    overflowX: "auto",
                    paddingTop: "16px",
                    paddingBottom: "10px",
                },
            },
            list: {
                style: {
                    px: {
                        xs: "2px",
                        sm: 0,
                    },
                    py: 0,
                    m: 0,
                },
                direction: { xs: "row" },
            },
        },
        side: {
            container: {
                style: {
                    position: "relative",
                    width: { xs: "100%" },
                    overflowY: "auto",
                    overflowX: "auto",
                    paddingTop: "16px",
                    paddingBottom: "10px",
                    height: "100%",
                },
            },
            list: {
                style: {
                    px: {
                        xs: "2px",
                        sm: 0,
                    },
                    py: 0,
                    m: 0,
                },
                direction: { xs: "row", md: "column" },
            },
        },
    },
});
