const initialState = {
    token: null,
    loggedIn: false
  }
  
  const TokenReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'NEW_TOKEN':
      return Object.assign({}, state, {
        token: action.data.token,
        loggedIn: action.data.loggedIn
      })
  
    default:
      return state
    }
  };
  
  //adding the token
  export const addToken = (token, loggedIn) => {
    return {
      type: 'NEW_TOKEN',
      data: { token, loggedIn }
    }
  }
  
  export default TokenReducer