import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { addMessage } from "../reducers/MessageReducer";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

export default function SnackBar({ message, open }) {
    const dispatch = useDispatch();
    const handleClose = (e) => {
        dispatch(addMessage("", false));
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(e) => handleClose(e)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                onClose={(e) => handleClose(e)}
                message={parse(message)}
                action={action}
                autoHideDuration={5000}
                sx={{
                    pointerEvents: "none",
                    "& .MuiSnackbarContent-root": {
                        backgroundColor: "black",
                    },
                    "&.MuiSnackbar-root": {
                        top: { xs: "unset", md: "unset" },
                        bottom: { xs: "0", md: "24px" },
                        left: { xs: ".5rem", md: "24px" },
                    },
                }}
            />
        </div>
    );
}
