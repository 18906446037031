/* eslint-disable default-case */
import React from "react";
import { Route, Routes } from "react-router-dom";
import Lobby from "./pages/Lobby/Lobby";
import Play from "./pages/Play/Play";
import EndScreen from "./pages/EndScreen/EndScreen";

import useSocketEvents from "./hooks/useSocketEvents";
import {
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
} from "./helpers/constant";
import {
    populatePlayers,
    updateRoomId,
    updateGameHost,
    updateGameStatus,
    playerRemoved,
    playerJoined,
    addGameType,
} from "./reducers/GameReducer";

import { addMessage, addError } from "./reducers/MessageReducer";
import { addStartTimer } from "./reducers/TimerReducer";

import { useDispatch, useSelector } from "react-redux";

import { titleCase } from "./helpers/Global";

import {
    addTitle,
    addPid,
    addMode,
    addCategory,
    addTriviaImage,
} from "./reducers/SettingsReducer";
import { updateVideo } from "./reducers/VideoReducer";

const GameRoutes = () => {
    const dispatch = useDispatch();
    const me = useSelector(({ player }) => player);
    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const gameType = useSelector(({ game }) => game.gameType);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );
    const currentPid = useSelector(({ settings }) => settings.currentPid);

    const appendRoomId = (roomId) => {
        //update URL to include roomId
        var queryParams = new URLSearchParams(window.location.search);

        // Set new or modify existing parameter value.
        queryParams.set("roomId", roomId);

        // Replace current querystring with the new one.
        window.history.replaceState(null, null, "?" + queryParams.toString());

        //update parent url as well with roomId
        let message = { action: "updateRoomId", roomId: roomId };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    // receives game-details when new player joins
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_DETAILS,
        null,
        (players, gameStatus, gameSettings, gameHost, roomId) => {
            // console.log(gameStatus, gameSettings);
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>Welcome</span> to the waiting lobby`,
                    true
                )
            );

            //update url add roomId
            appendRoomId(roomId);

            //update roomId
            dispatch(updateRoomId(roomId));

            //update gameHost
            dispatch(updateGameHost(gameHost));

            //update gameType
            dispatch(addGameType(gameSettings.gameType));

            //add title, pid, gameMode, category, triviaImage
            dispatch(addTitle(gameSettings.triviaTitle));
            dispatch(addPid(gameSettings.videoId));
            dispatch(addCategory(gameSettings.category));
            dispatch(addMode(gameSettings.gameMode));
            dispatch(addTriviaImage(gameSettings.triviaImage));

            //update gameStatus
            dispatch(updateGameStatus(gameStatus));
            //update players
            dispatch(populatePlayers(players));
        }
    );

    // receive update status
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_STATUS,
        null,
        (status) => {
            dispatch(updateGameStatus(status));
        }
    );

    // receive player removed message event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.QUESTIONS_START,
        null,
        (video) => {
            // console.log("QUESTIONS_START", video);
            dispatch(updateVideo(video));
        }
    );

    // receive player removed message event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_REMOVED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has been removed`,
                    true
                )
            );
            // fix for player being disappearing after game end/disconnect event in the backend
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));

            me.playerId == playerId &&
                dispatch(addError("You have been removed from the game"));
        }
    );

    // receive player joined event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_JOINED,
        null,
        (player) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${player.name}</span> joined the game`,
                    true
                )
            );
            dispatch(playerJoined(player));
        }
    );

    // receive starting game timer
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.START_COUNTDOWN,
        null,
        (timer, status) => {
            dispatch(addStartTimer(timer));
            dispatch(updateGameStatus(status));
        }
    );

    //callback when token authentication fails
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.CONNECT_ERROR,
        null,
        (err) => {
            //on connection refused, do something...
            console.log(err.message); // prints the message associated with the error
        }
    );

    //callback when Host changes game settings
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATED_SETTINGS,
        null,
        (gameSettings) => {
            dispatch(addGameType(gameSettings.gameType));

            //add title, pid, gameMode, category, triviaImage
            dispatch(addTitle(gameSettings.triviaTitle));
            dispatch(addPid(gameSettings.videoId));
            dispatch(addCategory(gameSettings.category));
            dispatch(addMode(gameSettings.gameMode));
            dispatch(addTriviaImage(gameSettings.triviaImage));
        }
    );

    //callback when Host changes game settings
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROOM_FULL,
        null,
        (errorMessage) => {
            dispatch(addError(errorMessage));
        }
    );

    // when game ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_ENDS,
        null,
        (data) => {
            dispatch(populatePlayers(data));
            dispatch(updateGameStatus("end"));
        }
    );

    //callback when players update
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYERS_UPDATE,
        null,
        (players, gameHost) => {
            dispatch(updateGameHost(gameHost));
            dispatch(populatePlayers(players));
        }
    );

    //callback when REDIRECT_NEW_ROOM
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REDIRECT_NEW_ROOM,
        null,
        (roomId) => {
            let url = null;
            switch (gameType) {
                case "category":
                    url = `${process.env.REACT_APP_URL}/${
                        process.env.REACT_APP_END_POINT
                    }/${titleCase(currentCategory.replace(/ /g, "+"))}`;
                    break;

                case "video":
                    url = `${process.env.REACT_APP_URL}/${process.env.REACT_APP_END_POINT}/${currentPid}`;
                    break;
            }
            window.top.location.href = `${url}/room/${roomId}`;
        }
    );

    // receive player disconnected from socket event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_DISCONNECTED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has disconnected`,
                    true
                )
            );
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));
        }
    );

    // receive game already started error
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.HANDLE_ERROR,
        null,
        (errorMessage) => {
            dispatch(addError(errorMessage));
        }
    );

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Lobby />} />
                <Route exact path="/play" element={<Play />} />
                <Route exact path="/end" element={<EndScreen />} />
            </Routes>
        </>
    );
};

export default GameRoutes;
