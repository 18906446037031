/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Box,
    Typography,
    CssBaseline,
    Container,
    Grid,
    Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Players from "../../components/Players/Players";
import styles from "./Play.module.css";
import { useTheme } from "@mui/material/styles";
import Timer from "../../components/Timer/Timer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    SOCKET_SEND_EVENTS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
    SOCKET_ACTIONS,
} from "../../helpers/constant";
import { useNavigate } from "react-router-dom";

import {
    updateMaxSeconds,
    updateQuestionCount,
    updateQuestion,
} from "../../reducers/QuestionsReducer";
import { playerReset, populatePlayers } from "../../reducers/GameReducer";
import { updateCorrectAnswer } from "../../reducers/AnswersReducer";
import useSocketEvents from "../../hooks/useSocketEvents";
import SnackBar from "../../helpers/SnackBar";
import MultipleAnswers from "../../components/MultipleAnswers/MultipleAnswers";
import ErrorPage from "../../components/ErrorPage/ErrorPage";

import circleBG from "./bg_trivia_livestream_circle.png";
import squareBG from "./bg_trivia_livestream_box.png";

import questionMark from "./bg_trivia_livestream_questionmark.png";
import exclamationPoint from "./bg_trivia_livestream_exclamation.png";

import { browserName, browserVersion } from "react-device-detect";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import { isMobileOrTablet } from "../../helpers/Global";

const Play = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const video = useRef();

    const matches = useMediaQuery("(min-width:400px)");

    const playerContainer = useRef();

    const isDesktop =
        useMediaQuery(theme.breakpoints.up("md")) === true ? true : false;

    const openSnackbar = useSelector(({ message }) => message.openSnackbar);

    const questionObj = useSelector(({ question }) => question);
    const videoUrl = useSelector(({ video }) => video.media);
    const currentTimer = useSelector(({ video }) => video.currentTimer);
    const [roundStart, setRoundStart] = useState(false);

    const message = useSelector(({ message }) => message.message);
    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const roomId = useSelector(({ game }) => game.roomId);
    const me = useSelector(({ player }) => player);
    const questionGrid = useRef();

    const correctAnswer = useSelector(({ answers }) => answers.correctAnswer);
    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const [selectedAns, setSelectedAns] = useState(null);
    const [clicked, setClicked] = useState(false);

    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
        theme.palette.wmColor.main
    );

    const [gameHeight, setGameHeight] = useState("auto");
    const [questionHeight, setQuestionHeight] = useState("auto");
    const [thumbnailHeight, setThumbnailHeight] = useState("auto");

    const error = useSelector(({ message }) => message.error);

    let [playSound, setPlaySound] = useState(false);

    const handlePlaySound = () => {
        setPlaySound((prev) => {
            return !prev;
        });
    };

    useEffect(() => {
        video.current.muted = !playSound;
    }, [playSound]);

    useEffect(() => {
        if (!video || !video.current) return;

        video.current.onloadedmetadata = () => {
            video.current.play();

            setTimeout(() => {
                if (
                    !browserName ||
                    browserName.toLowerCase().includes("safari") ||
                    isMobileOrTablet()
                ) {
                    return;
                }

                setPlaySound(true);
            }, 1000);
        };
    }, []);

    useEffect(() => {
        if (!videoUrl || !video || !video.current) return;

        video.current.src = videoUrl;
        video.current.currentTime = currentTimer;
    }, [videoUrl, currentTimer]);

    useEffect(() => {
        if (!isDesktop) {
            if (gameHeight >= 560 && gameHeight <= 650) {
                const buttonSize = 50 * 5;
                const timerSizer = 0;
                const offset = 0;
                const newheight =
                    gameHeight -
                    buttonSize -
                    timerSizer -
                    playerContainer.current.offsetHeight -
                    offset;

                setThumbnailHeight(`${newheight}px`);
                return;
            }

            setThumbnailHeight("auto");
        }
    }, [isDesktop, gameHeight]);

    // handle answer options clicked event
    const handleAnswerClick = (answer) => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.ANSWER_QUESTION, {
            roomId: roomId,
            playerId: me.playerId,
            answer: answer,
        });

        // also store the answer clicked
        setSelectedAns(answer);
        setClicked(true);
    };

    // check gameStatus and redirect to end page when game is over
    useEffect(() => {
        if (gameStatus === GAME_STATUS.END) {
            let message = { action: "endGame" };
            // method to send message to parent outside iframe
            window.parent.postMessage(message, "*");
            navigate("/end");
        }
    }, [gameStatus, navigate]);

    // receive showNextQuestion event
    const handleRoundStarts = useCallback(
        (question, questionIndex, totalQuestions, maxSeconds) => {
            // console.log(question, questionIndex, totalQuestions, maxSeconds);
            setRoundStart(true);
            dispatch(playerReset(["didAnswer", "isCorrect"]));
            setClicked(false);
            dispatch(updateCorrectAnswer(undefined));
            dispatch(updateMaxSeconds(15));
            dispatch(updateQuestionCount(10));
            dispatch(
                updateQuestion(
                    question.id,
                    questionIndex,
                    question.question,
                    question.type,
                    question.answers
                )
            );

            // method to send message to parent outside iframe
            // let message = { action: "refreshAds" };
            // window.parent.postMessage(message, "*");
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_STARTS,
        null,
        handleRoundStarts
    );

    // receive showNextQuestion event
    const handleRoundEnds = useCallback(
        (data) => {
            setRoundStart(false);
            dispatch(updateCorrectAnswer(data.correctAnswer));
            dispatch(populatePlayers(data.playersStats));
        },
        [dispatch]
    );

    // when round ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_ENDS,
        null,
        handleRoundEnds
    );

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                //if host, once a roomId is created from reactApp update watchmojo url
                case "gameHeight":
                    // console.log(
                    //     playerContainer.current.offsetHeight,
                    //     payload.height,
                    //     isDesktop
                    // );
                    setGameHeight(payload.height);
                    const newHeight =
                        payload.height -
                        (playerContainer && !isDesktop
                            ? playerContainer.current.offsetHeight
                            : 0);
                    setQuestionHeight(newHeight);
                    break;

                default:
                    break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        if (playerContainer.current) {
            let message = { action: "gameHeight" };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        }

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [playerContainer.current]);

    return (
        questionObj !== undefined &&
        (error === "" ? (
            <>
                <CssBaseline />
                <Container
                    sx={{
                        position: "relative",
                        padding: "0px !important",
                        width: "100% !important",
                        maxWidth: {
                            xs: "100% !important",
                            md: "56% !important",
                            lg: "55% !important",
                        },
                        height: gameHeight,
                    }}
                >
                    {/* bg images */}
                    <img
                        src={circleBG}
                        className={`${styles.bgIconsDefault} ${styles.circleBG}`}
                        alt="bg1"
                    />
                    <img
                        src={questionMark}
                        className={`${styles.bgIconsDefault} ${styles.questionBG}`}
                        alt="bg1"
                    />

                    <img
                        src={squareBG}
                        className={`${styles.bgIconsDefault} ${styles.squareBG}`}
                        alt="bg1"
                    />

                    <img
                        src={exclamationPoint}
                        className={`${styles.exclamationBG}`}
                        alt="bg1"
                    />
                    {/* bg images */}
                    <Grid
                        container
                        sx={{
                            ml: 0,
                            height: "100%",
                        }}
                        spacing={{ xs: "0" }}
                        className={styles.backgroundColor}
                    >
                        <Grid
                            item
                            xs={12}
                            md={2}
                            ref={playerContainer}
                            sx={{ height: { xs: "auto", lg: "100%" } }}
                        >
                            <Players location="side" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={10}
                            ref={questionGrid}
                            sx={{
                                position: "relative",
                                height: questionHeight,
                                maxHeight: { xs: `40rem`, md: "100%" },
                                minHeight: { xs: `30rem` },
                                width: "100%",
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        height: "auto",
                                        position: "relative",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            px: 0,
                                            py: 0,
                                            position: "relative",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <video
                                            ref={video}
                                            no-controls
                                            playsInline
                                            muted
                                            preload="metadata"
                                            style={{
                                                width: "100%",
                                                height: thumbnailHeight,
                                                pointerEvents: "none",
                                            }}
                                        ></video>
                                        <Button
                                            variant="outlined"
                                            startIcon={
                                                playSound ? (
                                                    <VolumeUpIcon fontSize="inherit" />
                                                ) : (
                                                    <VolumeOffIcon fontSize="inherit" />
                                                )
                                            }
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                color: theme.palette.textColor
                                                    .main,
                                                fontSize: "0.8rem",
                                                minWidth: "110px",
                                            }}
                                            onClick={handlePlaySound}
                                        >
                                            {playSound ? "Mute" : "Unmute"}
                                        </Button>
                                    </Box>
                                    {/* 3. end of answer box */}
                                    {roundStart === true && <Timer />}
                                </Box>
                                <Box sx={{ position: "relative" }}>
                                    <Typography
                                        id="questionIndex"
                                        variant="subtitle1"
                                        sx={{
                                            color: theme.palette.wmColor.main,
                                            fontWeight: "bold",
                                            letterSpacing: "-0.5px",
                                            pl: theme.pLeft.secondary,
                                            fontSize: {
                                                xs: "0.9rem",
                                                md: "0.9rem",
                                            },
                                            textAlign: "center",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Guess the Top &nbsp;&nbsp;
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: "#FFFFFF",
                                                fontSize: {
                                                    xs: "1.3rem",
                                                    md: "1.2rem",
                                                },
                                                textTransform: "capitalize",
                                                textAlign: "center",
                                                fontWeight: 600,
                                            }}
                                        >
                                            #{questionObj.question}
                                        </Typography>
                                    </Typography>
                                    <MultipleAnswers
                                        questionObj={questionObj}
                                        buttonBackgroundColor={
                                            buttonBackgroundColor
                                        }
                                        correctAnswer={correctAnswer}
                                        clicked={clicked}
                                        matches={matches}
                                        selectedAns={selectedAns}
                                        handleAnswerClick={handleAnswerClick}
                                        roundStart={roundStart}
                                    />

                                    {questionObj.question === "" && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                height: "100%",
                                                width: "100%",
                                                backgroundColor:
                                                    "rgba(48, 48, 50, 0.9)",
                                            }}
                                        ></Box>
                                    )}
                                </Box>
                                {/* 2. end of question box*/}
                            </Box>
                            {/* end of contentHolder box*/}

                            {message !== "" && (
                                <SnackBar
                                    open={openSnackbar}
                                    message={message}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </>
        ) : (
            <ErrorPage />
        ))
    );
};

export default Play;
