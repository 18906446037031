import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import playerCSS from "./PlayerLobbyView.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PlayerLobbyView() {
    const triviaImage = useSelector(
        ({ settings }) => settings.currentTriviaImage
    );

    const currentTitle = useSelector(({ settings }) => settings.currentTitle);
    const gameType = useSelector(({ game }) => game.gameType);
    const matches = useMediaQuery("(min-width:750px)");

    return (
        <>
            {triviaImage !== null && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: "auto",
                        px: 0,
                        py: {
                            xs: "0",
                            sm: ".8rem",
                        },
                        position: "relative",
                    }}
                >
                    <img
                        src={`${triviaImage}`}
                        srcSet={`${triviaImage}`}
                        alt={currentTitle}
                        loading="lazy"
                        style={{
                            width: "100%",
                            borderRadius: matches ? "10px" : "0",
                        }}
                    />
                </Box>
            )}
        </>
    );
}
