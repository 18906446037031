import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#181818",
    border: "3px solid #00b3e2",
    boxShadow: 24,
    p: 4,
    color: "#fff",
    borderRadius: "8px",
};

const CountDownModal = () => {
    const openCountDownModal = useSelector(
        ({ message }) => message.openCountDownModal
    );

    return (
        <Box>
            <Modal
                open={openCountDownModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={style} elevation={20}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        mb={2}
                    >
                        Starting in ...
                    </Typography>
                    <CountdownCircleTimer
                        isPlaying
                        duration={4}
                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        colorsTime={[3, 2, 1, 0]}
                    >
                        {() => <RenderTime />}
                    </CountdownCircleTimer>
                </Paper>
            </Modal>
        </Box>
    );
};

export default CountDownModal;

const RenderTime = () => {
    const startingTimer = useSelector(({ timer }) => timer.startingTimer);
    return (
        <Box>
            <Typography variant="h3">{startingTimer}</Typography>
        </Box>
    );
};
