import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import InviteSection from "../InviteSection/InviteSection";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Settings from "../Settings/Settings";
import { isMobileOrTablet } from "../../helpers/Global";

const LobbySettings = () => {
    const settingsContainer = useRef();

    const theme = useTheme();
    const [contentContainerHeight, setContentContainerHeight] =
        useState("auto");

    let currentTitle = useSelector(({ settings }) => settings.currentTitle);

    useEffect(() => {
        const newHeight = `${settingsContainer.current.offsetHeight - 4}`;
        setContentContainerHeight(newHeight);
    }, [settingsContainer, currentTitle]);

    return (
        <>
            <Box
                sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
                <Box
                    sx={{
                        display: "block",
                        width: "100%",
                        height: { xs: "48px" },
                        overflow: "hidden",
                        pl: { xs: theme.pLeft.main, md: "0px" },
                        pr: { xs: theme.pRight.main, md: "0px" },
                        mt: { xs: theme.px8.main },
                        mb: { xs: theme.px16.main },
                    }}
                >
                    <InviteSection />
                </Box>

                <Box
                    ref={settingsContainer}
                    sx={{
                        mb: { xs: 0, md: "1vh" },
                        width: "100%",
                        height: { xs: "auto" },
                        overflow: "hidden",
                        paddingTop: 0,
                    }}
                >
                    <Settings contentContainerHeight={contentContainerHeight} />
                </Box>
            </Box>
        </>
    );
};

export default LobbySettings;
