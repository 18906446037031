export const AVATAR_SIZE = {
    me: { xs: 58, sm: 60 },
    other: { xs: 48, sm: 50 },
    leaderboard: { xs: 35, sm: 45 },
};

export const COLORS = {
    wmTheme: "#00B3E2",
};

//constant pass when calling an socket emit or receive
export const SOCKET_ACTIONS = {
    SEND: true,
    RECEIVE: false,
};

//List of socket receive actions
export const SOCKET_RECEIVE_EVENTS = {
    CONNECT_ERROR: "connect_error",
    CURRENT_USER: "currentUser",
    GAME_DETAILS: "gameDetails",
    PLAYER_JOINED: "playerJoined",
    PLAYER_REMOVED: "playerRemoved",
    PLAYER_ANSWERED: "playerAnswered",
    PLAYERS_UPDATE: "playersUpdate",
    START_COUNTDOWN: "startCountdown",
    ROUND_COUNTDOWN: "roundCountdown",
    ROUND_STARTS: "roundStarts",
    ROUND_ENDS: "roundEnds",
    UPDATED_SETTINGS: "updatedSettings",
    ROOM_FULL: "roomFull",
    GAME_ENDS: "gameEnds",
    REDIRECT_NEW_ROOM: "redirectNewRoom",
    GAME_ONGOING: "gameOngoing",
    PLAYER_DISCONNECTED: "playerDisconnected",
    HANDLE_ERROR: "handleError",
    QUESTIONS_START: "questionsStart",

    UPDATE_STATUS: "updateStatus",
};

//List of socket emit actions
export const SOCKET_SEND_EVENTS = {
    JOIN_ROOM: "joinRoom",
    LEAVE_ROOM: "leaveRoom",
    START_GAME: "startGame",
    ANSWER_QUESTION: "answerQuestion",
    UPDATE_SETTINGS: "updateSettings",
    SOLO_GAME: "soloGame",
    PLAY_AGAIN: "playAgain",
    ADD_BOT: "addBot",
    QUESTION_VOTE: "questionVote",
};

export const GAME_STATUS = {
    WAITING: "waiting",
    STARTING: "starting",
    INGAME: "in-game",
    END: "end",
};

export const GAME_TYPE = {
    VIDEO: "video",
    CATEGORY: "category",
};

export const GAME_MODE = {
    SPRINT: "sprint",
    TRAINING: "training",
    MARATHON: "marathon",
};
